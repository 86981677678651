import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import SEO from 'react-seo-component';
import {Layout} from '../components/Layout';
import {Hero} from '../components/post/Hero';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import styled from 'styled-components';

const Content = styled.article`
    max-width: 66ch;
    margin: 0 auto 0 6rem;

    .gatsby-resp-image-wrapper {
        width: 150%;
        transform: translateX(-13.5%);
        > a {
            margin: 2rem 0;
        }

        @media screen and (max-width: 1280px) {
            width: 100%;
            transform: none;
        }
    }

    h2, h3 {
        margin: 2em 0 1em;
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
        margin: 0 auto;
    }
`;

export default ({ data }) => {
    const {
        siteUrl,
        siteLanguage,
        siteLocale,
        twitterUsername,
        authorName,
    } = useSiteMetadata();
    const { frontmatter, body, excerpt, fields } = data.mdx;
    const { title, date, hero } = frontmatter;
    return (
        <Layout>
            <SEO
                title={title}
                description={excerpt}
                image={`${siteUrl}${hero.publicURL}`}
                pathname={`${siteUrl}${fields.slug}`}
                siteLanguage={siteLanguage}
                siteLocale={siteLocale}
                twitterUsername={twitterUsername}
                author={authorName}
                article={true}
                publishedDate={date}
                modifiedDate={new Date(Date.now()).toISOString()}
            />
            <Hero heading={title} image={{
                src: hero.publicURL,
                alt: hero.name
            }} date={date} />
            <Content>
                <MDXRenderer>{body}</MDXRenderer>
                <p>
                    <br></br>
                    <br></br>
                    <AniLink swipe direction="right" to="/">&lt; Back to home</AniLink>
                </p>
            </Content>
        </Layout>
    );
};

export const query = graphql`
    query PostsBySlug($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            body
            excerpt
            frontmatter {
                title
                date(formatString: "Do MMMM YYYY")
                hero {
                    publicURL
                    name
                }
            }
            fields {
                slug
            }
        }
    }
`;