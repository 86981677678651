import React from 'react';
import styled from 'styled-components';

const HeroStyles = styled.div`
    padding: 3rem 0;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
`;

export const Hero = ({ heading,date, image }) => (
    <HeroStyles>
        <img src={image.src} alt={image.alt} />
        <h1>{ heading }</h1>
        <p>{date}</p>
    </HeroStyles>
);